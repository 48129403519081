import React, { Component } from "react";
import { Input, Col, Row } from "antd";

import QuerryContext from "../../../contexts/querry";

class AnswerRadio extends Component {
  state = {
    checked: false,
  };

  static contextType = QuerryContext;

  ansId = "";

  handleChange = (event) => {
    var checked = true;
    if (event.target.value === "") {
      event.target.checked = true;
      const tab = document.getElementsByName(event.target.name);
      tab.forEach((elem) => {
        if (elem !== event.target) {
          elem.value = "smth";
          elem.click();
          elem.value = "";
        }
      });
    } else {
      checked = false;
    }

    this.props.setHidden(!checked);
    this.setState({ checked: checked });
    if (checked)
      this.context.updateAnswer(this.ansId, this.props.questId + "-radio");
  };

  componentDidMount = () => {
    this.props.setHidden(true);
    const answ = this.context.getAnswer(this.props.questId + "-radio");
    if (answ) {
      if (answ === this.ansId) {
        this.setState({ checked: true });
        this.props.setHidden(false);
      }
    }
  };

  render() {
    this.ansId = this.props.questId + "-radio-" + this.props.aid;
    return (
      <div className="radio-answer">
        <Row>
          <Col span={23} offset={1}>
            <Input
              type="radio"
              checked={this.state.checked}
              value=""
              onClick={this.handleChange}
              name={this.props.questId + "-radio"}
              style={{ position: "relative", top: "4px", marginRight: "5px" }}
            />
            <label>{this.props.answerData.text}</label>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AnswerRadio;
