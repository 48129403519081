import React, { Component } from "react";
import { Redirect, Route, Switch, NavLink } from "react-router-dom";
import { Layout, Menu, Row, Dropdown } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import IdleTimer from "react-idle-timer";

import CredentialsContext from "../contexts/credentials";

import Questionnaires from "./Questionnaires";
import NewQuestionnaire from "./NewQuestionnaire";
import Users from "./Users";
import NewUser from "./NewUser";
import Dashboard from "./Dashboard";
import ChangePassword from "./ChangePassword"

import { dateDifference } from "../utils/date";

import Logo from "../images/2019_pgp-logo_pl.png";

import "../styles/Home.css";

const timeToLogout = 20 * 60 * 1000; //20 min

class Home extends Component {
  state = {
    data: null,
    timeCounter: "00:00",
    menuOpen: true,
  };

  lastActivity = new Date().toUTCString();

  componentDidMount = () => {
    const f = () => {
      const d1 = this.lastActivity;
      let d2 = new Date().getTime();
      d2 -= timeToLogout;
      d2 = new Date(d2).toUTCString();

      if (d1 < d2) {
        this.handleLogOut();
        return;
      }

      this.setState({ timeCounter: dateDifference(d1, d2) });
    };

    f();

    this.intervalFunc = setInterval(f, 1000);
  };

  componentWillUnmount = () => {
    clearInterval(this.intervalFunc);
  };

  static contextType = CredentialsContext;

  handleLogOut = () => {
    this.context.logout();
  };

  generateRoutes = () => {
    return (
      <Switch>
        <Route path="/admin/questionnaires/" component={Questionnaires} />
        <Route path="/admin/new-questionnaire/" component={NewQuestionnaire} />
        <Route path="/admin/users/" component={Users} />
        <Route path="/admin/new-user/" component={NewUser} />
        <Route path="/admin/dashboard/" component={Dashboard} />
        <Route path="/admin/change-password/" component={ChangePassword} />
        <Redirect from="/admin/" to="/admin/dashboard" />
      </Switch>
    );
  };

  onAction = () => {
    this.lastActivity = new Date().toUTCString();
  };

  handleClick = () => {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  };

  userMenu = (
    <Menu>
      <Menu.Item key="0">
        <NavLink to="/admin/change-password">Change password</NavLink>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" onClick={this.handleLogOut}><b>Log out</b></Menu.Item>
    </Menu>
  );

  render() {
    const { Header, Content, Sider } = Layout;
    return (
      <Layout style={{ height: "100%" }}>
        <IdleTimer element={document} onAction={this.onAction} />
        <Sider hidden={!this.state.menuOpen}>
          <img
            src={Logo}
            alt="GMP logo"
            style={{
              margin: "auto",
              width: "100%",
              marginBottom: "30px",
            }}
          />
          <Menu
            mode="inline"
            theme="dark"
            defaultSelectedKeys={["0"]}
            style={{ textAlign: "right" }}
          >
            <Menu.Item key="0">
              <NavLink to="/admin/dashboard">Dashboard</NavLink>
            </Menu.Item>
            <Menu.Item key="1">
              <NavLink to="/admin/questionnaires">Questionnaires</NavLink>
            </Menu.Item>
            <Menu.Item key="2">
              <NavLink to="/admin/users">Users</NavLink>
            </Menu.Item>
            {/*<Menu.Item key="3" onClick={this.handleLogOut}>
              <Icon type="logout" /> <b>Logout</b>
            </Menu.Item>*/}
          </Menu>
          <button
            className="menuButton"
            style={{ width: "80%", marginTop: "30px" }}
            onClick={this.handleClick}
          >
            <ArrowLeftOutlined />
          </button>
        </Sider>
        <Layout className="layout" style={{ height: "100%" }}>
          <Header
            style={{ height: "97.958px", background: "rgb(56, 69, 101)" }}
          >
            <Row
              style={{ marginTop: "15px", textAlign: "right", color: "white" }}
            >
              {this.state.menuOpen ? null : (
                <button
                  className="menuButton"
                  style={{ float: "left", width: "70px" }}
                  onClick={this.handleClick}
                >
                  <ArrowRightOutlined style={{ fontSize: "20px" }} />
                </button>
              )}

              <b
                style={{ fontSize: "15px", marginRight: "20px" }}
                title="Time to automatic logout"
              >
                {this.state.timeCounter}
              </b>

              <Dropdown overlay={this.userMenu} trigger={["click"]}>
                <button
                  className="invisibleButton"
                  style={{ fontSize: "16px" }}
                >
                  <UserOutlined />
                  {" " + localStorage.username}
                </button>
              </Dropdown>
            </Row>
          </Header>
          <Content style={{ padding: "0 0px" }}>
            <div
              style={{
                background: "#fff",
                padding: 24,
                minHeight: "100%"
              }}
            >
              {this.generateRoutes()}
            </div>
          </Content>
          {/*<Footer style={{ textAlign: "center", position: "fixed", bottom: "0", width: "100%" }}>GMP © PUT 2022</Footer>*/}
        </Layout>
      </Layout>
    );
  }
}

export default Home;
