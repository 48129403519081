import React from "react";

import ApolloInterface from "../Apollo";
import { gql } from "apollo-boost";
import { Form, Icon, Input, Button, Row, Col } from "antd";

import CredentialsContext from "../contexts/credentials";

class ChangePassword extends React.Component {

    static contextType = CredentialsContext;

    changePassword = (oldPassword, newPassword) => {
        const variables = { oldPassword, newPassword };
        const gqlQuery = gql`
          mutation ChangePassword(
            $oldPassword: String!, $newPassword: String!
          ) {
            changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
          }
        `;
        ApolloInterface.runMutation(this.context.token, variables, gqlQuery)
          .then(() => {
            this.props.history.push("/admin/dashboard");
          })
          .catch((err) => {
            console.log(err);
          });
    }

    handleSubmit = (e) => {
        e.preventDefault();
    
        let wrong = false;
    
        this.props.form.validateFields((err, values) => {
          if (!err) {
            //console.log('Received values of form: ', values);
          } else {
            wrong = true;
          }
        });
    
        if (wrong) {
          return;
        }
    
        const newPassword = this.props.form.getFieldValue("newPassword");
        const oldPassword = this.props.form.getFieldValue("oldPassword");
    
        this.changePassword(oldPassword, newPassword)
      };

  render = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="changePassword">
        <Row>
          <Col span={16} offset={4}>
            <h1>Change password</h1>
            <Form onSubmit={this.handleSubmit} className="changePasswordForm">
            <Form.Item>
                {getFieldDecorator("oldPassword", {
                  rules: [
                    { required: true, message: "Please input the old password!" },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Old password"
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("newPassword", {
                  rules: [
                    { required: true, message: "Please input new password!" },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="New password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  Change password
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  };
}

export default Form.create({ name: "changePassword" })(ChangePassword);
