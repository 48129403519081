import React, { Component } from "react";
import { Input, Col, Row } from "antd";

import QuerryContext from "../../../contexts/querry";

class AnswerText extends Component {
  state = {
    value: "",
  };

  ansId = "";

  static contextType = QuerryContext;

  handleChange = (event) => {
    this.props.setHidden(event.target.value === "");
    this.context.updateAnswer(event.target.value, this.ansId);
    this.setState({ value: event.target.value });
  };

  componentDidMount = () => {
    this.props.setHidden(true);
    const answ = this.context.getAnswer(this.ansId);
    if (answ) {
      this.setState({ value: answ });
      this.props.setHidden(answ === "");
    }
  };

  render() {
    this.ansId = this.props.questId + "-text-" + this.props.aid;
    return (
      <div className="text-answer">
        <Row style={{marginTop: "5px"}}>
          <Col span={22} offset={1}>
            <Input
              value={this.state.value}
              onChange={this.handleChange}
              name={this.ansId}
              placeholder={this.props.answerData.text}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default AnswerText;
