import QuestionData from "../EtGen4.json";

const prepare_string_for_latex = (str) => {
  str = str.replace("\\", "\\backslash")
  str = str.replace("~", "\\~")
  str = str.replace("$", "\\$")
  str = str.replace("_", "\\_")
  str = str.replace("^", "\\^")
  str = str.replace("{", "\\{")
  str = str.replace("}", "\\}")
  str = str.replace("\\backslash", "$\\backslash$")

  return str
};

const fillUpQuestion = (quest, suffix, filledUp) => {
  let arr = quest.answers;
  quest.type = "question";
  for (let i = 0; i < arr.length; ++i) {
    fillUpAnswer(quest.id + suffix, i, arr[i], filledUp);
  }
};

const retrieve_first_answer = (quest, suffix, filledUp) => {
  const aid = quest.id + suffix + "-text-" + 0;
  const found = filledUp.find((el) => el.question === aid);
  if (found) {
    quest.answers[0].value = prepare_string_for_latex(found.text);
  }
}

const fillUpAnswer = (qid, index, answer, filledUp) => {
  switch (answer.type) {
    case "radio":
      fillUpAnswerRadio(qid, index, answer, filledUp);
      break;
    case "text":
      fillUpAnswerText(qid, index, answer, filledUp);
      break;
    case "textarea":
      fillUpAnswerTextArea(qid, index, answer, filledUp);
      break;
    case "checkbox":
      fillUpAnswerCheckBox(qid, index, answer, filledUp);
      break;
    case "ant-slider":
      fillUpAnswerAntSlider(qid, index, answer, filledUp);
      break;
    case "family-tree":
      fillUpFamilyTree(qid, index, answer, filledUp);
      break;
    case "input-number":
      fillUpAnswerInputNumber(qid, index, answer, filledUp);
      break;
    case "select":
      fillUpAnswerSelect(qid, index, answer, filledUp);
      break;
    default:
      console.log('ERROR: Unkown answer type: "' + answer.type + '"');
      return;
  }

  if (answer.type !== "family-tree") {
    let arr = answer.questions;
    if (!arr) {
      arr = [];
    }
    //for (let i = 0; i < arr.length; ++i) {
      //fillUpQuestion(arr[i], "", filledUp);
    if(arr.length > 0){
      let quest = arr[0]
      retrieve_first_answer(quest, "", filledUp)
      if(quest.answers && quest.answers.length > 0){
        if(quest.answers[0].type !== "text"){
          console.log('WARNING: Subquestion answer type other than "text" is not implemented as of yet!');
        }
        let v = quest.answers[0].value
        if(v){
          v = prepare_string_for_latex(v)
        }
        switch(answer.type){
          case 'radio':
            if(answer.value && answer.value !== ""){
              answer.value = v
              answer.type = "radio-text"
            }
            break;
          case 'checkbox':
            if(answer.value && answer.value !== ""){
              answer.value = v
              answer.type = "checkbox-text"
            }
            break;
          default:
            console.log('WARNING: Only checkboxes and radios can have subquestions as of yet!');
        }
      }
    }
  }
};

const fillUpAnswerRadio = (qid, index, answer, filledUp) => {
  const aid = qid + "-radio-" + index;
  const found = filledUp.find(
    (el) => el.text === aid && el.question === qid + "-radio"
  );
  if (found) {
    answer.value = "checked";
  }
};

const fillUpAnswerText = (qid, index, answer, filledUp) => {
  const aid = qid + "-text-" + index;
  const found = filledUp.find((el) => el.question === aid);
  if (found) {
    answer.text = "\\textbf{" + prepare_string_for_latex(answer.text+": ") + "}" + prepare_string_for_latex(found.text)
  }
};

const fillUpAnswerTextArea = (qid, index, answer, filledUp) => {
  const aid = qid + "-textarea-" + index;
  const found = filledUp.find((el) => el.question === aid);
  answer.type = "text"
  if (found) {
    answer.text = prepare_string_for_latex(found.text);
  }
};

const fillUpAnswerCheckBox = (qid, index, answer, filledUp) => {
  const aid = qid + "-checkbox-" + index;
  const found = filledUp.find((el) => el.question === aid);
  if (found && found.text === "true") {
    answer.value = prepare_string_for_latex("true");
  }
};

const fillUpAnswerAntSlider = (qid, index, answer, filledUp) => {
  const aid = qid + "-slider-" + index;
  const found = filledUp.find((el) => el.question === aid);
  if (found) {
    answer.value = Number(found);
  }
};

const prepareNode = (nodeId, name, side, answer, filledUp) => {
  const properties = [];

  if (
    answer.questions &&
    answer.questions.length > 0 &&
    answer.questions[0].answers &&
    answer.questions[0].answers.length > 0
  ) {
    const arr = answer.questions[0].answers;
    const qid = answer.questions[0].id + nodeId;

    if (arr[0].type === "radio" || arr[0].type === "checkbox") {
      const aid = qid + "-" + arr[0].type + "-";
      for (let i = 0; i < arr.length; ++i) {
        const found = filledUp.find((el) => el.text === aid + i);
        if (found) {
          properties.push({
            type: "property",
            name: "Odp",
            value: arr[i].text,
          });
          break;
        }
      }
    } else {
      const found = filledUp.find(
        (el) => el.question === qid + "-" + arr[0].type + "-0"
      );
      if (found) {
        properties.push({
          type: "property",
          name: "Odp",
          value: found.text,
        });
      }
    }
  }

  return {
    type: "family-tree-node",
    text: name,
    properties,
    nodeSide: side,
    nodes: [],
  };
};

const fillUpFamilyTree = (qid, index, answer, filledUp) => {
  const aid = qid + "-family-tree-" + index;
  answer.startWidth = "6";

  answer.nodes = [
    {
      type: "family-tree-node",
      text: "ankietowany",
      properties: [],
      nodes: [],
    },
  ];

  const chars = ["f", "m"];
  const side = ["left", "right"];

  for (let i = 0; i <= 1; ++i) {
    const nodeId = aid + (i === 0 ? "mother" : "father");
    const name = i === 0 ? "matka" : "ojciec";
    answer.nodes[0].nodes.push(
      prepareNode(nodeId, name, side[i], answer, filledUp)
    );
    for (let j = 0; j <= 1; ++j) {
      const nodeId2 =
        aid + chars[i] + (j === 0 ? "grandmother" : "grandfather");
      const name2 = j === 0 ? "babcia" : "dziadek";
      answer.nodes[0].nodes[i].nodes.push(
        prepareNode(nodeId2, name2, side[j], answer, filledUp)
      );
    }
  }
};

const fillUpAnswerInputNumber = (qid, index, answer, filledUp) => {
  const aid = qid + "-number-" + index;
  const found = filledUp.find((el) => el.question === aid);
  answer.type="text"
  if (found) {
    answer.text = "\\textbf{" + prepare_string_for_latex(answer.text+": ") + "}" + prepare_string_for_latex(found.text)
  }
};

const fillUpAnswerSelect = (qid, index, answer, filledUp) => {
  const aid = qid + "-select-" + index;
  const found = filledUp.find((el) => el.question === aid);
  answer.type="text"
  if (found) {
    let f = found.text;
    let t = answer.options.find(v => v.value === f).text

    answer.text = "\\textbf{" + prepare_string_for_latex(answer.text + ": ") + "}" + prepare_string_for_latex(t)
  }
};

const generateObject = (filledUp) => {
  if (!filledUp) {
    filledUp = [];
  }

  let result = JSON.parse(JSON.stringify(QuestionData))

  for (let q in result.questions) {
    fillUpQuestion(result.questions[q], "", filledUp);
  }
  return result;
};

export { generateObject, prepare_string_for_latex };
