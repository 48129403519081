import React, { Component } from "react";
import { Layout, Button } from "antd";

class Policy extends Component {
  render() {
    const { Header, Content } = Layout;

    return (
      <div className="Questions">
        <Header>
          <h1 style={{ color: "white" }}>Polityka prywatności</h1>
        </Header>
        <Content style={{ marginTop: "50px", marginLeft: "10%", width: "80%" }}>
          <h3>Polityka prywatności</h3>
          <p style={{ textAlign: "justify" }}>
            Niniejsza Polityka prywatności określa zasady przetwarzania oraz
            ochrony danych osobowych dotyczących Użytkowników w związku z
            korzystaniem przez nich z serwisu:{" "}
            <a href="http://etgen.genompolski.pl">
              http://etgen.genompolski.pl
            </a>{" "}
            (dalej „Serwis”) umożliwiającego wypełnienie ankiety uczestnika
            projektu (dalej „Ankieta”) pn. ECBiG – Europejskie Centrum
            Bioinformatyki i Genomiki, POIR.04.02.00 30 A004/16-02 (dalej
            „Projekt”). Projekt realizowany jest wspólnie przez Instytut Chemii
            Bioorganicznej Polskiej Akademii Nauk (ICHB PAN) oraz Politechnikę
            Poznańską. Jego celem jest stworzenie Genomicznej Mapy Polski.
            Dostępna w Serwisie Ankieta umożliwia zebranie informacji
            pozwalających na charakterystykę identyfikacji narodowo-etnicznej
            Użytkownika i stanowi warunek konieczny dla jego udziału w części
            Projektu związanej z opisaniem zmienności genetycznej mniejszości
            etnicznych zamieszkujących terytorium Polski.
          </p>

          <p style={{ textAlign: "justify" }}>
            Jako administratorzy danych osobowych zbieranych za pośrednictwem
            Serwisu, ICHB PAN oraz Politechnika Poznańska szanują prawo do
            prywatności Użytkowników Serwisu. W szczególności, obie instytucje
            dbają o ochronę danych osobowych Użytkowników oraz stosują
            odpowiednie rozwiązania organizacyjne i techniczne zapobiegające
            ingerencji w prywatność Użytkowników przez osoby trzecie. Nasze
            działania są ukierunkowane na zagwarantowanie Użytkownikowi poczucia
            pełnego bezpieczeństwa na poziomie odpowiednim do obowiązującego
            prawa, w tym:
          </p>

          <ol style={{ textAlign: "justify" }}>
            <li>
              Rozporządzenia Parlamentu Europejskiego i Rady 2016/679 z dnia 27
              kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z
              przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
              takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
              rozporządzenie o ochronie danych);
            </li>
            <li>Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych.;</li>
            <li>Ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne.</li>
          </ol>

          <p style={{ textAlign: "justify" }}>
            Korzystanie z Serwisu jest realizowane za pomocą bezpiecznego
            protokołu SSL, który zwiększa ochronę transmisji danych w sieci
            Internet.
          </p>

          <h3 style={{ marginTop: "60px" }}>
            I Administrator i Inspektor Danych Osobowych
          </h3>

          <p style={{ textAlign: "justify" }}>
            Administratorami danych osobowych dotyczących Użytkownika zbieranych
            za pośrednictwem Serwisu są ICHB PAN z siedzibą przy ul. Z.
            Noskowskiego 12/14, 61-704 Poznań; REGON 000849327 NIP
            777-00-02-062, oraz Politechnika Poznańska z siedzibą przy Pl. Marii
            Skłodowskiej-Curie 560-965 Poznań, REGON: 000001608 NIP:
            777-00-03-699.
          </p>

          <p style={{ textAlign: "justify" }}>
            Administratorzy wyznaczyli inspektorów ochrony danych osobowych, z
            którymi można kontaktować się poprzez pocztę elektroniczną:{" "}
            <a href="mailto:dpo@ibch.poznan.pl">dpo@ibch.poznan.pl</a> (ICHB
            PAN) lub <a href="mailto:iod@put.poznan.pl">iod@put.poznan.pl</a>{" "}
            (Politechnika Poznańska).
          </p>

          <h3 style={{ marginTop: "60px" }}>II Gromadzenie danych</h3>

          <p style={{ textAlign: "justify" }}>
            Ankieta pozwala na zebranie następujących danych: imię i nazwisko,
            miejsce zamieszkania (ograniczone do miejscowości), adres e-mail,
            telefon, przynależność do grupy narodowej lub etnicznej. Podanie ww.
            danych, w tym danych osobowych, a także zgoda na ich przetwarzanie
            są całkowicie dobrowolne, zarazem jednak, są konieczne do wzięcia
            przez Użytkownika udziału w Projekcie. Wszelkie przekazane nam dane
            osobowe są przetwarzane wyłącznie w zakresie i celu, na jaki
            Użytkownik wyraził zgodę.
          </p>

          <h3 style={{ marginTop: "60px" }}>
            III Przetwarzanie danych osobowych
          </h3>
          <p style={{ textAlign: "justify" }}>
            Zebrane za pośrednictwem Serwisu dane dotyczące Użytkownika mogą być
            wykorzystane jedynie w celach i okresie niezbędnych do prawidłowej
            realizacji Projektu. Szczegóły określają odpowiednie klauzule
            informacyjne wyświetlane przed przystąpieniem do wypełniania
            Ankiety.
          </p>
          <p style={{ textAlign: "justify" }}>
            Gromadzone dane osobowe Użytkowników nie są przekazywane
            organizacjom międzynarodowym czy do państw trzecich. Dane osobowe
            mogą być przetwarzane przez inne podmioty zgodnie z prawem UE lub
            prawem krajowym.
          </p>

          <h3 style={{ marginTop: "60px" }}>IV Prawa Użytkownika</h3>
          <p style={{ textAlign: "justify" }}>
            Użytkownik w zakresie procesu przetwarzania jego danych posiada
            prawo do: dostępu do swoich danych, ich sprostowania, usunięcia lub
            ograniczenia ich przetwarzania, a także wniesienia skargi do organu
            nadzorczego w zakresie ochrony danych osobowych – Prezesa Urzędu
            Ochrony Danych Osobowych.
          </p>

          <h3 style={{ marginTop: "60px" }}>V Wykorzystanie plików cookies</h3>
          <p style={{ textAlign: "justify" }}>
            Zgodnie z art. 173 Ustawy z dnia 16 lipca 2004 r. Prawo
            telekomunikacyjne, Serwis korzysta z plików cookies (tzw.
            ciasteczek). Pliki cookies to niewielkie pliki przechowywane w
            pamięci urządzenia końcowego Użytkownika, wysyłane w momencie
            odwiedzenia Serwisu. Cookies są wykorzystywane w celu:
          </p>
          <ol style={{ textAlign: "justify" }}>
            <li>
              ułatwienia Użytkownikowi korzystania z Serwisu podczas jego
              przeglądania;
            </li>
            <li>
              skojarzenia Użytkownika w przypadku ponownego połączenia Serwisu z
              urządzeniem, na którym zostały zapisane;
            </li>
            <li>
              tworzenia statystyk, które pomagają zrozumieć, w jaki sposób
              Użytkownicy Serwisu korzystają ze stron internetowych, co
              umożliwia ulepszanie ich struktury i zawartości;
            </li>
            <li>
              dostosowania zawartości stron internetowych Serwisu do określonych
              preferencji Użytkownika oraz optymalizacji korzystania ze stron
              internetowych, dostosowanych do indywidualnych potrzeb
              Użytkownika;
            </li>
          </ol>
          <p style={{ textAlign: "justify" }}>
            W ramach Serwisu wykorzystujemy następujące rodzaje plików cookies:
          </p>
          <ul style={{ textAlign: "justify" }}>
            <li>
              „sesyjne” – przechowywane są na urządzeniu końcowym Użytkownika do
              czasu wylogowania, opuszczenia strony internetowej lub wyłączenia
              przeglądarki internetowej;
            </li>
            <li>
              „stałe” – przechowywane na urządzeniu końcowym Użytkownika przez
              czas określony w parametrach plików cookies lub do czasu ich
              usunięcia przez Użytkownika;
            </li>
            <li>
              „wydajnościowe” – umożliwiają zbieranie informacji o sposobie
              korzystania ze stron internetowych Serwisu;
            </li>
            <li>
              „niezbędne” – umożliwiające korzystanie z usług dostępnych w
              ramach Serwisu;
            </li>
            <li>„własne” – zamieszczane przez Serwis.</li>
          </ul>
          <p style={{ textAlign: "justify" }}>
            Pliki cookies w żaden sposób nie są łączone z danymi osobowymi
            Użytkownika Serwisu i nie są wykorzystywane do określenia tożsamości
            Użytkownika. Zakres informacji zbieranych automatycznie zależy od
            ustawień przeglądarki internetowej Użytkownika. Użytkownik powinien
            sprawdzić ustawienia swojej przeglądarki, aby dowiedzieć się jakie
            informacje są udostępniane przez przeglądarkę automatycznie.
            Przeglądarki internetowe zazwyczaj domyślnie dopuszczają
            przechowywanie plików cookies na urządzeniu końcowym Użytkownika.
            Użytkownicy Serwisu mogą w każdej chwili dokonać zmiany ustawień w
            tym zakresie. Szczegółowe informacje na temat konfiguracji ustawień
            dotyczących plików cookies zawiera sekcja „Pomocy” dostępna w menu
            przeglądarki, np.:{" "}
            <a href="https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies">
              Internet Explorer
            </a>
            ,{" "}
            <a href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">
              Microsoft Edge
            </a>
            ,{" "}
            <a href="http://support.mozilla.org/pl/kb/ciasteczka">
              Mozilla Firefox
            </a>
            ,{" "}
            <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647">
              Chrome
            </a>
            ,{" "}
            <a href="http://help.opera.com/opera/Windows/2393/pl/private.html#deleteData">
              Opera
            </a>
            , <a href="https://support.apple.com/pl-pl/HT201265">Safari</a>.
          </p>

          <p style={{ textAlign: "justify" }}>
            Użytkownik może zablokować lub ograniczyć zapisywanie plików cookies
            na swoim urządzeniu końcowym, jednak wyłączenie obsługi plików
            cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa
            lub utrzymania preferencji Użytkownika może utrudnić, a w skrajnych
            przypadkach także uniemożliwić korzystanie z Serwisu.
          </p>

          <h3 style={{ marginTop: "60px" }}>VI Zmiana polityki prywatności</h3>
          <p style={{ textAlign: "justify" }}>
            ICHB PAN oraz Politechnika Poznańska zastrzegają sobie prawo zmiany
            polityki prywatności, o ile będzie wymagać tego obowiązujące prawo
            lub zmianie ulegną warunki technologiczne funkcjonowania Serwisu.
          </p>
          <Button type="primary" href="/GMP_policy.pdf" style={{marginTop: "100px", marginBottom: "50px"}}>Pobierz PDF</Button>
        </Content>
      </div>
    );
  }
}

export default Policy;
