import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClientBoost, { gql } from "apollo-boost";

class ApolloInterface {
  static link = "https://api.gmp.auriga.cs.put.poznan.pl/graphql";
  static runQuery = (token, gqlQuery) => {
    const httpLink = createHttpLink({
      uri: this.link,
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });
    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      fetchOptions: {
        mode: "no-cors",
      },
    });
    return client.query({
      query: gqlQuery,
    });
  };
  static runMutation = (token, variables, gqlQuery) => {
    const httpLink = createHttpLink({
      uri: this.link,
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });
    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    return client.mutate({
      variables: variables,
      mutation: gqlQuery,
    });
  };
  static login = (username, password) => {
    const client = new ApolloClientBoost({
      uri: this.link,
    });
    return client.query({
      query: gql`
              {
                login(username: "${username}", password: "${password}"){
                  userId
                  token
                  tokenExpiration
                }
              }
            `,
    });
  };

  static questLogin = (hashlink, pin) => {
    const client = new ApolloClientBoost({
      uri: this.link,
    });
    return client.query({
      query: gql`
            {
              questionnaireLogin(hashlink: "${hashlink}", pin: "${pin}"){
                token
                tokenExpiration
              }
            }
          `,
    });
  };

  static updateAnswer = (text, question, token, first = true) => {
    const variables = { text: text, question: question };

    const gqlQuery = gql`
      mutation UpdateAnswer($text: String!, $question: String!) {
        updateAnswer(answerInput: { text: $text, question: $question }) {
          _id
        }
      }
    `;
    ApolloInterface.runMutation(token, variables, gqlQuery)
      .then(() => {
        localStorage.removeItem("_ans_" + question);
      })
      .catch((err) => {
        if (first) {
          console.log("_ans_" + question, text);
          //couldn't send
          localStorage.setItem("_ans_" + question, text);
        }
      });
  };
}

export default ApolloInterface;
