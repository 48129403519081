import React, { Component } from "react";
import { Form, Card, Row, Col } from "antd";
import Answer from "./Answer";

class Question extends Component {
  prepareTitle = (quest) => {
    if (quest.text === "") {
      quest.text = false;
    }

    const len = ("" + this.props.number).length;

    return (
      <div>
        {quest.text
          ? (this.props.number ? this.props.number + ". " : "") + quest.text
          : null}
        {quest.hint && quest.text ? <br /> : null}
        {quest.hint ? (
          <i style={{ fontWeight: "lighter", marginLeft: 11 + len * 5 + "px" }}>
            {quest.hint}
          </i>
        ) : null}
      </div>
    );
  };

  renderQuestion = (quest) => {
    if (quest.hint === "") {
      quest.hint = false;
    }

    if (!quest.answers) {
      quest.answers = [];
    }
    const suffix = this.props.questIdSuffix ? this.props.questIdSuffix : "";
    const preparedAnswers = quest.answers.map((answer, index) => {
      return (
        <Row key={index}>
          <Answer
            questId={quest.id + suffix}
            answerData={answer}
            aid={index}
          />
        </Row>
      );
    })

    if(quest.text && quest.text.length > 0){
    return (
      <Form.Item>
        <Card title={this.prepareTitle(quest)}>
          {preparedAnswers}
        </Card>
      </Form.Item>
    );
    }
    else{
      return (
        <Form.Item>
            {preparedAnswers}
        </Form.Item>
      );
    }
  };

  render() {
    return (
      <div
        className="Question"
        hidden={this.props.hidden}
        style={{ marginTop: "10px" }}
      >
        <Row>
          <Col span={22} offset={1}>
            {this.renderQuestion(this.props.questionData)}
          </Col>
        </Row>
      </div>
    );
  }
}

export default Question;
