import React, { Component } from 'react';
import Question from './Question';
import AnswerRadio from './Answers/AnswerRadio.js';
import AnswerText from './Answers/AnswerText.js';
import AnswerTextArea from './Answers/AnswerTextArea.js';
import AnswerCheckBox from './Answers/AnswerCheckBox.js';
import AnswerAntSlider from './Answers/AnswerAntSlider.js';
import AnswerFamilyTree from './Answers/AnswerFamilyTree.js';
import AnswerInputNumber from './Answers/AnswerInputNumber.js';
import AnswerSelect from './Answers/AnswerSelect.js';

class Answer extends Component {
    state = {
        hidden: false
    };
    renderNestedQuestions = (questions) => {
        if(!questions){
            questions = []
        }
        return (
            <div className="nested-questions">
            {
                questions.map((question, index) => {
                    return <Question questionData={question} hidden={this.state.hidden} key={index} />
                })
            }
            </div>
        )
    }

    setHidden = (hid) => {
        this.setState({
            hidden: hid
        });
    }

    renderAnswerBody = (answer, questId) => {
        switch(answer.type){
            case 'radio':
                return <AnswerRadio setHidden={this.setHidden} questId={questId} answerData={answer} aid={this.props.aid} />
            case 'text':
                return <AnswerText setHidden={this.setHidden} questId={questId} answerData={answer} aid={this.props.aid} />
            case 'textarea':
                return <AnswerTextArea setHidden={this.setHidden} questId={questId} answerData={answer} aid={this.props.aid} />
            case 'checkbox':
                return <AnswerCheckBox setHidden={this.setHidden} questId={questId} answerData={answer} aid={this.props.aid} />
            case 'ant-slider':
                return <AnswerAntSlider setHidden={this.setHidden} questId={questId} answerData={answer} aid={this.props.aid} />
            case 'family-tree':
                return <AnswerFamilyTree setHidden={this.setHidden} questId={questId} answerData={answer} aid={this.props.aid} />
            case 'input-number':
                return <AnswerInputNumber setHidden={this.setHidden} questId={questId} answerData={answer} aid={this.props.aid} />
            case 'select':
                return <AnswerSelect setHidden={this.setHidden} questId={questId} answerData={answer} aid={this.props.aid} />
            default:
                return <p style={{color: "red"}}>Unkown answer type</p>
        }
    }

    render() {
        return (
                <div className="answer">
                        {this.renderAnswerBody(this.props.answerData, this.props.questId)}
                        {this.props.answerData.type !== 'family-tree' ? this.renderNestedQuestions(this.props.answerData.questions) : <div />}
                </div>
        );
    }
}

export default Answer;