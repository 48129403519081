const containsPhrase = (text, phrase) => {
  const t = text.toLowerCase();
  const p = phrase.toLowerCase();

  return t.includes(p);
};

const generateBinaryFile = (ab, filename) => {
  var buffer = Buffer(ab.byteLength);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buffer.length; ++i) {
      buffer[i] = view[i];
  }

  var dlink = document.createElement("a")
  dlink.setAttribute(
    "href",
    "data:application/pdf;base64," + buffer.toString('base64')
  )
  dlink.setAttribute("download", filename)

  if (document.createEvent) {
    var event = document.createEvent("MouseEvents")
    event.initEvent("click", true, true)
    dlink.dispatchEvent(event)
  } else {
    dlink.click()
  }
}

export { containsPhrase, generateBinaryFile };
