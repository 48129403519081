import React, { Component } from "react";

import Questions from "./Questionnaire/Questions";
import PinLogin from "./Questionnaire/PinLogin";

import { Button } from "antd";

import ApolloInterface from "../Apollo";

import CredentialsContext from "../contexts/credentials";

class Questionnaire extends Component {
  state = {
    hash: localStorage.questHash,
    token: localStorage.questToken,
    tokenExpiration: localStorage.questTokenExpiration,
    online: true,
  };

  static contextType = CredentialsContext;

  getQuestToken = () => {
    if (this.state.token) {
      if (this.state.tokenExpiration < Date.now()) {
        return null;
      }
      return this.state.token;
    }
    return null;
  };

  getHash = () => {
    if (this.state.token) {
      if (this.state.tokenExpiration < Date.now()) {
        return null;
      }
      return this.state.hash;
    }
    return null;
  };

  sendAnswers = () => {
    let flag = true;

    let key_list = [];

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      if (key.startsWith("_ans_")) {
        flag = false;

        key_list.push(key);
      }
    }

    this.setState({ online: flag });

    const token = this.state.token;
    if (!token) return;

    for (let i = 0; i < key_list.length; ++i) {
      const key = key_list[i];
      const it = localStorage.getItem(key);
      const key2 = key.replace(/^_ans_/, "");

      ApolloInterface.updateAnswer(it, key2, token, false);
    }
  };

  componentDidMount = () => {
    this.context.logout()
    const hash = this.getHash();
    //console.log(hash);
    if (hash && this.props.match.params.hash !== hash) {
      this.questLogout();
    }

    this.sendAnswers();

    setInterval(this.sendAnswers, 5000);
  };

  questLogin = (hash, token, tokenExpiration) => {
    localStorage.questHash = hash;
    localStorage.questToken = token;
    tokenExpiration = Date.now() + (tokenExpiration - 1) * 60 * 1000;
    localStorage.questTokenExpiration = tokenExpiration;
    this.setState({
      hash: hash,
      token: token,
      tokenExpiration: tokenExpiration,
    });
  };

  questLogout = () => {
    localStorage.removeItem("questToken");
    this.setState({ hash: null, token: null, tokenExpiration: null });
  };

  generateQuestSubPage = () => {
    if (this.getHash() !== null || this.props.answers)
      return (
        <Questions
          questLogout={this.questLogout}
          getQuestToken={this.getQuestToken}
          answers={this.props.answers}
        />
      );
    return (
      <PinLogin
        urlHash={this.props.match.params.hash}
        questLogin={this.questLogin}
      />
    );
  };

  render() {
    return (
      <div className="Questionnaire">
        {this.getHash() !== null ? (
          <div
            style={{
              height: "104px",
              width: "100px",
              background: "white",
              position: "fixed",
              right: "0px",
              zIndex: 999,
            }}
          >
            <div style={{ marginTop: "5px" }}>
              State: {this.state.online ? "online" : "offline"}
            </div>
            <div
              style={{
                height: "25px",
                width: "25px",
                backgroundColor: this.state.online ? "green" : "red",
                borderRadius: "50%",
                display: "block",
                margin: "auto",
                boxShadow:
                  "rgba(0,255,0,0.2) 0px 2px 10px, inset rgba(0,255,0,0.2) 0px -1px 2px",
              }}
            ></div>
            <Button style={{ marginTop: "10px" }} onClick={this.questLogout}>
              Wyloguj
            </Button>
          </div>
        ) : null}
        {this.generateQuestSubPage()}
      </div>
    );
  }
}

export default Questionnaire;
