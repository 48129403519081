import React from "react"

import { printDate } from "./date";

const stringSorter = (name) => {
  return (a, b) => {
    var str1 = a[name];
    var str2 = b[name];

    if (typeof str1 !== "string") str1 = "";
    if (typeof str2 !== "string") str2 = "";
    return str1.localeCompare(str2);
  };
};

const normalSorter = (name) => {
  return (a, b) => {
    if (a[name] < b[name]) return -1;
    if (a[name] > b[name]) return 1;
    return 0;
  };
};

const questionnairesColumns = [
  /*{
    title: "ID",
    dataIndex: "_id",
    key: "_id",
  },*/
  {
    title: "Sample ID",
    dataIndex: "sample_id",
    key: "sample_id",
    sorter: stringSorter("sample_id"),
  },
  {
    title: "E-mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Admin E-mail",
    dataIndex: "admin_email",
    key: "admin_email",
  },
  {
    title: "Hashlink",
    dataIndex: "hashlink",
    key: "hashlink",
    render: (text, record, index) => <a href={"./../quest/" + text}>{text}</a>,
  },
  {
    title: "Last accessed",
    dataIndex: "last_accessed",
    key: "last_accessed",
    render: (text, record, index) => printDate(text),
    sorter: stringSorter("last_accessed"),
  },
  {
    title: "Created",
    dataIndex: "created",
    key: "created",
    render: (text, record, index) => printDate(text),
    sorter: stringSorter("created"),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Last exported",
    dataIndex: "last_printed",
    key: "last_printed",
    render: (text, record, index) => printDate(text),
    sorter: stringSorter("last_printed"),
  },
  {
    title: "New pin",
    dataIndex: "pin",
    key: "pin",
  },
  {
    title: "Export to PDF",
    dataIndex: "export_to_pdf",
    key: "export_to_pdf"
  }
];

const usersColumns = [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    sorter: stringSorter("username"),
  },
];

export { stringSorter, normalSorter, questionnairesColumns, usersColumns };
